<template>
  <div>
    <section class="bg-dark pb-3 pt-5">
      <div class="container py-5">
        <div class="row align-items-center mt-2 mt-md-0 pt-md-4 py-lg-2">
          <!--          <div class="col-md-4 order-md-2 mb-5 mb-md-0">-->
          <!--            <div class="parallax mx-auto"-->
          <!--                 style="max-width: 416px; transform: translate3d(0px, 0px, 0px) rotate(0.0001deg); transform-style: preserve-3d; backface-visibility: hidden; pointer-events: none;">-->
          <!--              <div class="parallax-layer position-relative" data-depth="0.1"-->
          <!--                   style="transform: translate3d(1.7px, -1.2px, 0px); transform-style: preserve-3d; backface-visibility: hidden; position: relative; display: block; left: 0px; top: 0px;">-->
          <!--                <img src="img/job-board/hero-banner-2/layer01.svg" alt="Layer"></div>-->
          <!--              <div class="parallax-layer" data-depth="0.15"-->
          <!--                   style="transform: translate3d(2.5px, -1.7px, 0px); transform-style: preserve-3d; backface-visibility: hidden; position: absolute; display: block; left: 0px; top: 0px;">-->
          <!--                <img src="img/job-board/hero-banner-2/layer02.svg" alt="Layer"></div>-->
          <!--              <div class="parallax-layer" data-depth="0.2"-->
          <!--                   style="transform: translate3d(3.4px, -2.3px, 0px); transform-style: preserve-3d; backface-visibility: hidden; position: absolute; display: block; left: 0px; top: 0px;">-->
          <!--                <img src="img/job-board/hero-banner-2/layer03.svg" alt="Layer"></div>-->
          <!--              <div class="parallax-layer" data-depth="0.4"-->
          <!--                   style="transform: translate3d(6.7px, -4.6px, 0px); transform-style: preserve-3d; backface-visibility: hidden; position: absolute; display: block; left: 0px; top: 0px;">-->
          <!--                <img src="img/job-board/hero-banner-2/layer04.svg" alt="Layer"></div>-->
          <!--              <div class="parallax-layer" data-depth="0.16"-->
          <!--                   style="transform: translate3d(2.7px, -1.9px, 0px); transform-style: preserve-3d; backface-visibility: hidden; position: absolute; display: block; left: 0px; top: 0px;">-->
          <!--                <img src="img/job-board/hero-banner-2/layer05.svg" alt="Layer"></div>-->
          <!--              <div class="parallax-layer" data-depth="0.2"-->
          <!--                   style="transform: translate3d(3.4px, -2.3px, 0px); transform-style: preserve-3d; backface-visibility: hidden; position: absolute; display: block; left: 0px; top: 0px;">-->
          <!--                <img src="img/job-board/hero-banner-2/layer06.svg" alt="Layer"></div>-->
          <!--              <div class="parallax-layer" data-depth="0.25"-->
          <!--                   style="transform: translate3d(4.2px, -2.9px, 0px); transform-style: preserve-3d; backface-visibility: hidden; position: absolute; display: block; left: 0px; top: 0px;">-->
          <!--                <img src="img/job-board/hero-banner-2/layer07.svg" alt="Layer"></div>-->
          <!--              <div class="parallax-layer" data-depth="0.2"-->
          <!--                   style="transform: translate3d(3.4px, -2.3px, 0px); transform-style: preserve-3d; backface-visibility: hidden; position: absolute; display: block; left: 0px; top: 0px;">-->
          <!--                <img src="img/job-board/hero-banner-2/layer08.svg" alt="Layer"></div>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="col-md-8 order-md-1" :class="{'col-md-12': isAdvisor}">
            <h1 v-if="!isAdvisor" class="display-5 pt-2 text-light pb-0 mb-4 mb-lg-4">Welcome back<br> <span
                class="text-primary">{{ userRole.name }}</span></h1>

            <h1 v-if="isAdvisor" class="display-5 pt-2 text-light pb-0 mb-4 mb-lg-4">Welcome <span
                class="text-primary">{{ userRole.name }}</span></h1>

              <p v-if="userRole.demo_mode" class="text-white fw-bold">
                This is a demo account. Jobs created will not feature in finances or platform statistics.
              </p>

            <!-- Calls to action -->
<!--            <router-link v-if="isAdvisor"-->
<!--                         to="/advisors/find-a-lawyer"-->
<!--                         class="btn btn-primary btn-lg rounded-pill w-100 w-lg-auto ms-auto mb-4">-->
<!--              <i class="fi-search me-2"></i>-->
<!--              Find a lawyer-->
<!--            </router-link>-->
            <!-- / Calls to action -->

            <!-- Stats -->
            <!--            <stats></stats>-->
            <!-- / Stats -->
          </div>
        </div>
      </div>
    </section>
    <section class="position-relative bg-white rounded-xxl-4 zindex-5" style="margin-top: -30px;">
      <div class="container pt-5 pb-2 mb-4 mb-md-5 pb-5" style="padding-top: 50px !important;">

        <div class="row">
          <!--          <div class="col-md-8">-->
          <div class="col-md-12">
            <!--            <h2 class="h3 pb-2 pb-sm-3">Requires Your Attention:</h2>-->
            <provider-things-requiring-attention v-if="isProvider"></provider-things-requiring-attention>
            <client-things-requiring-attention v-if="isClient"></client-things-requiring-attention>
            <advisor-things-requiring-attention v-if="isAdvisor"></advisor-things-requiring-attention>
          </div>
          <!--          <div class="col-md-4">-->
          <!--            <h2 class="h3 pb-2 pb-sm-3">Activity Feed</h2>-->
          <!--            <timeline :limit="10" :job-id="null"></timeline>-->
          <!--          </div>-->
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Stats from "./Dashboard/Stats";
import Timeline from "../components/Timeline";
import ProviderThingsRequiringAttention from "./Dashboard/ProviderThingsRequiringAttention";
import ClientThingsRequiringAttention from "./Dashboard/ClientsThingsRequiringAttention";
import AdvisorThingsRequiringAttention from "./Dashboard/AdvisorThingsRequiringAttention";

export default {
  name: "Dashboard",
  components: {
    AdvisorThingsRequiringAttention,
    ClientThingsRequiringAttention, ProviderThingsRequiringAttention, Stats, Timeline},
  computed: {
    ...mapGetters('auth', {
      user: 'user',
      userRole: 'userRole',
      isAdvisor: 'isAdvisor',
      isProvider: 'isProvider',
      isClient: 'isClient'
    })
  },
  data() {
    return {
      crumbs: [
        {
          href: "#",
          label: "Home",
          active: true,
        },
      ],
    }
  }
}
</script>

<style scoped>
.text-primary {
  color: rgb(var(--primary-colour)) !important;
}
</style>