<template>
  <div class="dashboard-favourite-lawyers card border-0">
    <div class="card-body">
      <h4 class="card-title">Favourite Lawyers</h4>
      <p>
        Manage and contact your favourite professionals, or make a new search
      </p>

      <!-- Favourites -->
      <favourite-card v-for="(favourite, i) in favourites"
                      :key="i"
                      :favourite="favourite"
                      @view-more-details="viewMoreDetailsFor(favourite)"
                      class="mb-3"></favourite-card>
      <!-- / Favourites -->

      <!-- / Button -->
      <a class="btn btn-primary btn btn-lg rounded-pill"
         href="/advisors/find-a-lawyer"
      ><i class="fi-search me-2"></i>
        Find a Professional
      </a>
      <!-- / Button -->

      <!-- Modals -->
      <favourite-more-details-modal @close="viewingMoreDetailsFor = null"
                                    @start-recommending="startRecommending"
                                    :favourite="viewingMoreDetailsFor"
      ></favourite-more-details-modal>

      <recommend-lawyer-modal @close="recommending = null"
                              :favourite="recommending"
      ></recommend-lawyer-modal>
      <!-- / Modals -->
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import FavouriteMoreDetailsModal from "../../../Favourites/FavouriteMoreDetailsModal";
import RecommendLawyerModal from "../../../Favourites/RecommendLawyerModal";
import FavouriteCard from "../../../Favourites/FavouriteCard";

export default {
  name: "FavouriteLawyers",
  components: {FavouriteMoreDetailsModal, RecommendLawyerModal, FavouriteCard},
  data() {
    return {
      viewingMoreDetailsFor: null,
      recommending: null
    }
  },
  computed: {
    ...mapGetters('favourites', {
      favourites: 'favourites'
    })
  },
  methods: {
    viewMoreDetailsFor(lawyer) {
      this.viewingMoreDetailsFor = lawyer;
    },

    startRecommending() {
      this.recommending = this.viewingMoreDetailsFor;
      this.viewingMoreDetailsFor = null;
    }
  }
}
</script>

<style lang="scss">
.dashboard-favourite-lawyers {
  .favourite-card {
    padding: 13px !important;
  }
}
</style>