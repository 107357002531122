<template>
  <header
      class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top"
      :class="{'height-small': heightSmall}"
      data-scroll-header
  >
    <authenticated-navbar :minimal="minimal"
                          :hide-messaging-icon="hideMessagingIcon"
                          v-if="showAuthenticatedNavbar"></authenticated-navbar>
    <no-user-role-navbar v-if="showNoUserRoleNavbar"></no-user-role-navbar>
    <guest-navbar v-if="showGuestNavbar"></guest-navbar>
  </header>
</template>

<script>
import {mapGetters} from 'vuex'
import AuthenticatedNavbar from './Navbar/AuthenticatedNavbar.vue'
import NoUserRoleNavbar from "./Navbar/NoUserRoleNavbar";
import GuestNavbar from './Navbar/GuestNavbar.vue'

export default {
  components: {AuthenticatedNavbar, NoUserRoleNavbar, GuestNavbar},
  props: ['heightSmall', 'minimal', 'hideMessagingIcon'],
  computed: {
    ...mapGetters('auth', {
      user: 'user',
      userRole: 'userRole'
    }),

    showAuthenticatedNavbar() {
      if (this.$route.path == '/2fa') {
        return false;
      }
      return this.user && this.userRole;
    },

    showNoUserRoleNavbar() {
      if (this.$route.path == '/2fa') {
        return true;
      }
      return this.user && !this.userRole;
    },

    showGuestNavbar() {
      return !this.user;
    }
  },
  setup() {

  },
}
</script>

<style scoped lang="scss">
.height-small {
  padding-top: 6px;
  padding-bottom: 6px;
}

@media (screen and max-width: 991px) {
  .height-small {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}
</style>