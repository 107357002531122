import {createRouter, createWebHistory} from 'vue-router'
import store from "../store"
import VueBodyClass from 'vue-body-class'
import Dashboard from "../views/Dashboard";
import {useToast} from 'vue-toastification';
const _ = require('lodash');

const toast = useToast();

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            auth: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: {
            bodyClass: 'bg-secondary',
            guest: true
        }
    },
    {
        path: '/company/:slug',
        name: 'Company Whitelabel Router',
        component: () => import(/* webpackChunkName: "company-whitelabel-router" */ '../views/CompanyWhitelabelRouter.vue'),
        meta: {
            bodyClass: 'bg-white',
            guest: true
        }
    },
    {
        path: '/landing',
        name: 'Landing',
        component: () => import(/* webpackChunkName: "landing" */ '../views/Landing.vue'),
        meta: {
            bodyClass: 'bg-white',
            guest: true
        }
    },
    {
        path: '/landing/:id',
        name: 'Landing - View Panel Provider',
        component: () => import(/* webpackChunkName: "landing-view-panel" */ '../views/Landing/ViewPanelProvider.vue'),
        meta: {
            bodyClass: 'bg-white',
            guest: true
        }
    },
    {
        path: '/forgot-password',
        name: 'Forgot Password',
        component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword.vue'),
        meta: {
            bodyClass: 'bg-secondary',
            guest: true
        }
    },
    {
        path: '/reset-password',
        name: 'Reset Password',
        component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue'),
        meta: {
            bodyClass: 'bg-secondary',
            guest: true
        }
    },
    {
        path: '/select-user-role',
        name: 'Select User Role',
        component: () => import(/* webpackChunkName: "select-user-role" */ '../views/SelectUserRole.vue'),
        meta: {
            bodyClass: 'bg-secondary'
        }
    },
    {
        path: '/2fa',
        name: '2FA',
        component: () => import(/* webpackChunkName: "2fa" */ '../views/2FA.vue'),
        meta: {
            bodyClass: 'bg-secondary',
            auth: true
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
        meta: {
            bodyClass: 'bg-secondary',
            auth: true
        }
    },
    {
        path: '/advisors/find-a-lawyer',
        name: 'Advisors - Find a Lawyer',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "advisors-find-a-lawyer" */ '../views/FindALawyer/FindALawyer.vue'),
        meta: {
            bodyClass: 'bg-secondary',
            auth: true,
            advisor: true
        }
    },
    {
        path: '/clients/find-a-lawyer',
        name: 'Clients - Find a Lawyer',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "clients-find-a-lawyer" */ '../views/FindALawyer/FindALawyer.vue'),
        meta: {
            bodyClass: 'bg-secondary',
            auth: true,
            client: true
        }
    },
    {
        path: '/guests/find-a-lawyer',
        name: 'Guests - Find a Lawyer',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "guests-find-a-lawyer" */ '../views/FindALawyer/FindALawyer.vue'),
        meta: {
            bodyClass: 'bg-secondary',
            guest: true
        }
    },
    {
        path: '/jobs',
        name: 'Jobs',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "jobs" */ '../views/Jobs/Index.vue'),
        meta: {
            bodyClass: 'bg-white',
            auth: true
        }
    },
    {
        path: '/jobs/:id',
        name: 'View Job',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "view-job" */ '../views/Jobs/Show.vue'),
        meta: {
            bodyClass: 'bg-white',
            auth: true
        }
    },
    {
        path: '/jobs/:id/quotes/create',
        name: 'Create Quote',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "create-quote" */ '../views/Jobs/Quotes/Create.vue'),
        meta: {
            bodyClass: 'bg-white',
            auth: true,
            provider: true
        }
    },
    {
        path: '/jobs/:id/close',
        name: 'Close Job',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "close-job" */ '../views/Jobs/CloseJob.vue'),
        meta: {
            bodyClass: 'bg-white',
            auth: true,
            provider: true
        }
    },
    {
        path: '/jobs/:id/confirm-closure',
        name: 'Confirm Job Closure',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "close-job" */ '../views/Jobs/ConfirmJobClosure.vue'),
        meta: {
            bodyClass: 'bg-white',
            auth: true,
            client: true
        }
    },
    {
        path: '/users',
        name: 'Users',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "users" */ '../views/Users/Index.vue'),
        meta: {
            bodyClass: 'bg-white',
            auth: true,
            role: 'admin'
        }
    },
    {
        path: '/redeem-invite/:id',
        name: 'Redeem Company Invite',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "redeem-invite" */ '../views/CompanyInvites/Redeem.vue'),
        meta: {
            bodyClass: 'bg-white',
            auth: true
        }
    },
    {
        path: '/setup-account-from-company-invite/:id',
        name: 'Setup Company Account',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "setup-account" */ '../views/CompanyInvites/SetupAccount.vue'),
        meta: {
            bodyClass: 'bg-white',
            guest: true
        }
    },
    {
        path: '/redeem-client-invite/:id',
        name: 'Redeem Client Invite',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "redeem-client-invite" */ '../views/ClientInvites/Redeem.vue'),
        meta: {
            bodyClass: 'bg-white',
            auth: true
        }
    },
    {
        path: '/setup-account-from-client-invite/:id',
        name: 'Setup Client Account',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "setup-client-account" */ '../views/ClientInvites/SetupAccount.vue'),
        meta: {
            bodyClass: 'bg-white',
            guest: true
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
        meta: {
            bodyClass: 'bg-white',
            auth: true,
            role: 'admin'
        }
    },
    {
        path: '/revenue',
        name: 'Revenue',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "revenue" */ '../views/Revenue/Index.vue'),
        meta: {
            bodyClass: 'bg-white',
            auth: true,
            advisor: true
        }
    },
    {
        path: '/whitelabel-configuration',
        name: 'Whitelabel Configuration',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "whitelabel-configuration" */ '../views/WhitelabelConfiguration/Edit.vue'),
        meta: {
            bodyClass: 'bg-secondary',
            auth: true,
            advisor: true
        }
    },
    {
        path: '/my-firm',
        name: 'My Firm',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "my-firm" */ '../views/MyFirm/Edit.vue'),
        meta: {
            bodyClass: 'bg-secondary',
            auth: true,
            provider: true
        }
    },
    {
        path: '/favourites',
        name: 'Favourites',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "favourites" */ '../views/Favourites.vue'),
        meta: {
            bodyClass: 'bg-white',
            auth: true,
            advisor: true
        }
    },
    {
        path: '/messaging',
        name: 'Messaging',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "messaging" */ '../views/Messaging.vue'),
        meta: {
            bodyClass: 'bg-secondary',
            auth: true
        }
    },
    {
        path: '/status-update/:updateCode',
        name: 'Provide Status Update',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "status-update" */ '../views/StatusUpdate.vue'),
        meta: {
            bodyClass: 'bg-white'
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// Wait for Vuex Persist to do it's thing
const waitForStorageToBeReady = async (to, from, next) => {
    await store.restored
    next()
}
router.beforeEach(waitForStorageToBeReady)

// Auth Guard
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        let isLoggedIn = store.getters['auth/loggedIn'];
        let otpVerified = store.getters['auth/otpVerified'];
        let user = store.getters['auth/user'];

        // Set user role from query if needed
        if (to.query.forUserRole && isLoggedIn) {
            let allUserRoles = store.getters['auth/userRoles'];
            let preferredUserRole = _.find(allUserRoles, userRole => {
                return Number(userRole.id) === Number(to.query.forUserRole);
            });
            if (preferredUserRole) {
                store.dispatch('auth/setUserRole', preferredUserRole);
            }
        }

        let userRoleSelected = store.getters['auth/userRole'] !== null;

        if (isLoggedIn && userRoleSelected) {
            next()
            return
        } else if (isLoggedIn && !userRoleSelected) {
            if (to.path == '/2fa') {
                next();
               return;
            }
            if (user.google2fa_enabled && !otpVerified) {
                next('/2fa?to=' + to.path)
                return;
            }
            next('/select-user-role?to=' + to.path)
            return
        } else {
            // toast.info("Please login to continue");
            next('/login?to=' + to.path)
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (store.getters['auth/loggedIn']) {
            toast.error("You can't access that page while logged in");
            next('/')
            return
        }
        next()
    } else {
        next()
    }
})

// User Role Type Guard
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.provider)) {
        if (store.getters['auth/userRoleType'] === 'provider') {
            next()
            return
        }
        toast.error("You don't have permission to access that page");
        next('/')
    } else if (to.matched.some(record => record.meta.advisor)) {
        if (store.getters['auth/userRoleType'] === 'advisor') {
            next()
            return
        }
        toast.error("You don't have permission to access that page");
        next('/')
    } else if (to.matched.some(record => record.meta.client)) {
        if (store.getters['auth/userRoleType'] === 'client') {
            next()
            return
        }
        toast.error("You don't have permission to access that page");
        next('/')
    } else if (to.matched.some(record => record.meta.advisorOrClient)) {
        if (
            store.getters['auth/userRoleType'] === 'advisor'
            || store.getters['auth/userRoleType'] === 'client'
        ) {
            next()
            return
        }
        toast.error("You don't have permission to access that page");
        next('/')
    } else {
        next()
    }
})

// Role Guard
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.role)) {
        if (store.getters['auth/userRoleRoles'].includes(to.meta.role)) {
            next()
            return
        }
        toast.error("You don't have permission to access that page");
        next('/')
    } else {
        next()
    }
})

// Body classes
const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => {
    vueBodyClass.guard(to, next)
});

export default router
