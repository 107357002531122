<template>
  <a class="navbar-brand me-0 me-xl-4" href="/" @click.prevent="handleLogoClicked">

    <img v-if="!isLoading && whitelabelConfiguration && whitelabelConfiguration.logo_dark && whitelabelImgSrc"
      class="d-block navbar-whitelabel-logo" :key="`whitelabel-logo-${updateKey}`" :src="whitelabelImgSrc" alt="Logo" />

    <img
      v-if="!isLoading && (!whitelabelConfiguration || (whitelabelConfiguration && !whitelabelConfiguration.logo_dark))"
      class="d-block" src="/img/logo.png" width="116" alt="Logo" />

  </a>
</template>

<script>
import temporaryUrls from "../../../api/temporary-urls";
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      temporaryLink: null,
      updateKey: 1,
      isLoadingTemporaryLink: false
    }
  },

  created() {
    if (this.whitelabelConfiguration && this.whitelabelConfiguration.logo_dark) {
      this.attemptGetFromCacheOrLoad();
      // this.loadTemporaryLink();
    }
  },

  computed: {
    ...mapGetters('whitelabelConfiguration', {
      isLoading: 'isLoading',
      whitelabelConfiguration: 'whitelabelConfiguration'
    }),

    ...mapGetters('auth', {
      loggedIn: 'loggedIn'
    }),

    whitelabelImgSrc() {
      if (this.temporaryLink) {
        return this.temporaryLink.url;
      }
      return null;
      // return '/img/logo.png';
    }
  },

  methods: {
    ...mapActions('temporaryImages', {
      getTemporaryImageByKey: 'getTemporaryImageByKey',
      addTemporaryImageToCache: 'addTemporaryImageToCache'
    }),

    handleLogoClicked() {
      if (!this.loggedIn) {
        if (this.whitelabelConfiguration) {
          this.$router.push('/landing');
        } else {
          this.$router.push('/login');
        }
      } else {
        this.$router.push('/');
      }
    },

    attemptGetFromCacheOrLoad() {
      this.getTemporaryImageByKey('whitelabel-config-logo-dark-' + this.whitelabelConfiguration.id).then(r => {
        this.temporaryLink = r;
      }).catch(() => {
        this.loadTemporaryLink();
      })
    },

    loadTemporaryLink() {
      let vm = this;
      vm.isLoadingTemporaryLink = true;
      temporaryUrls.getWhiteLabelLogoDarkTemporaryUrl(this.whitelabelConfiguration.id).then(r => {
        vm.isLoadingTemporaryLink = false;
        vm.temporaryLink = r.data;
        vm.addTemporaryImageToCache({
          key: 'whitelabel-config-logo-dark-' + this.whitelabelConfiguration.id,
          value: vm.temporaryLink
        });
        // vm.updateKey += 1;
      }).catch(e => {
        console.log(e);
        vm.isLoadingTemporaryLink = false;
        vm.temporaryLink = null;
      });
    }
  }
}
</script>

<style scoped lang="scss">
.navbar-whitelabel-logo {
  max-height: 25px;
  width: auto;
}
</style>