<template>
    <div class="panel-pricing-wrapper">
        <a @click.prevent="openModal" href="#" class="btn btn-primary btn-block w-100">Start Conversation</a>

        <!-- Modal -->
        <vue-final-modal v-model="showModal">
            <div class="modal-dialog" role="document" v-if="panelConfiguration">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0">
                        <h5 class="modal-title">
                            Start Conversation With {{ panelConfiguration.display_name }}
                        </h5>
                        <button type="button" class="btn-close" aria-label="Close" @click="closeModal"></button>
                    </div>
                    <div class="modal-body" v-if="localContext" style="text-align: left;">

                        <!-- Select speciality -->
                        <div v-if="!form.speciality">

                            <p>
                                What does your enquiry relate to?
                            </p>

                            <div class="speciality">
                                <a v-for="(speciality, i) in localContext.specialities" :key="i"
                                    @click.prevent="selectSpeciality(speciality)" href="#"
                                    class="btn btn-outline-primary me-2 mb-1">
                                    {{ speciality.name }}
                                </a>
                            </div>

                        </div>
                        <!-- / Select speciality -->

                        <!-- Has selected speciality -->
                        <div v-if="form.speciality" class="recommend-form">
                            <!-- Speciality -->
                            <div class="mb-3">
                                <label class="form-label" for="speciality">Speciality:</label> {{ form.speciality.name }}
                            </div>
                            <!-- / Speciality -->


                            <!-- Job Description -->
                            <div class="mb-4">
                                <label class="form-label" for="jobDescription">What would you like to discuss?</label>
                                <textarea :disabled="isSaving"
                                    placeholder="Just a bit of detail to help your lawyers with the context, including whether you prefer fixed or variable fees"
                                    v-model="form.description" rows="4" class="form-control" id="jobDescription" />
                            </div>
                            <!-- / Job Description -->


                        </div>
                        <!-- / Has selected speciality -->

                    </div>
                    <div class="modal-footer" v-if="localContext && form.speciality">
                        <button type="button" class="btn btn-secondary btn-sm"
                            @click.prevent="clearSpeciality()">Back</button>
                        <button :disabled="!canSave || isSaving" @click.prevent="startConversation" type="button"
                            class="btn btn-primary btn-sm">
                            <spinner v-if="isSaving" class="spinner-border-sm me-2"></spinner>

                            {{ isSaving ? 'Starting Conversation...' : 'Start Conversation' }}
                        </button>
                    </div>
                </div>
            </div>
        </vue-final-modal>
        <!-- / Modal -->
    </div>
</template>

<script>
import _ from 'lodash';
import Display from '../../mixins/Display';
import RequiresContext from '../../mixins/RequiresContext';
import Spinner from '../Spinner.vue';
import { useToast } from 'vue-toastification';
const toast = useToast();
import axios from 'axios';

export default {
    props: ['panelConfiguration'],
    components: { Spinner },
    mixins: [Display, RequiresContext],
    data() {
        return {
            showModal: false,

            form: {
                speciality: null,
                description: '',
            },

            isSaving: false,
            serverErrors: []
        }
    },
    methods: {
        reset() {
            this.form = {
                speciality: null,
                description: '',
            };
        },

        clearSpeciality() {
            this.form.speciality = null;
        },

        selectSpeciality(speciality) {
            this.form.speciality = speciality;
        },

        determineSearchParams() {
            return {
                speciality_id: this.form.speciality ? this.form.speciality.id : null,
                description: this.form.description,
                company_id: this.panelConfiguration.company_id
            };
        },

        openModal() {
            this.showModal = true;
        },

        closeModal() {
            this.showModal = false;
        },

        startConversation() {
            this.isSaving = true;
            this.serverErrors = [];

            axios.post(window.API_BASE + '/jobs/start-conversation-with-panel-provider', this.determineSearchParams())
                .then(r => {
                    this.isSaving = false;
                    this.showModal = false;
                    this.reset();
                    this.$router.push('/jobs/' + r.data.id);
                })
                .catch(error => {
                    this.isSaving = false;
                    let errors;
                    if (typeof e.response.data === 'object') {
                        errors = _.flatten(_.toArray(e.response.data.errors));
                    } else {
                        errors = ['Something went wrong. Please try again.'];
                    }
                    this.serverErrors = errors;
                    toast.error('There was an error starting your conversation. Please try again.');
                });
        }
    },
    computed: {
        canSave() {
            return this.form.speciality && this.form.description;
        }
    }
}
</script>

<style scoped lang="scss">
.panel-pricing-wrapper {
    width: 100%;
}

.btn-outline-primary {
    color: rgb(var(--primary-colour)) !important;
    border-color: rgb(var(--primary-colour)) !important;
}

.btn-outline-primary:hover {
    background-color: rgb(var(--primary-colour)) !important;
    color: white !important;
}
</style>