<template>
  <div class="mt-4">
    <h5>Specialities</h5>

    <div v-if="lawyer.specialities.length === 0" class="bg-secondary rounded-3 p-4 py-3">
      This lawyer has no specialities
    </div>

    <div class="speciality mt-4" v-for="(speciality, i) in lawyer.specialities" :key="i">
      <b>{{ speciality.name }}</b>
      <div class="mt-2">
        <span v-for="(subspeciality, ii) in getSubspecialities(speciality)"
              :key="ii"
              class="badge bg-secondary me-2 mb-2">{{ subspeciality.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpecialitiesSection",
  props: ['lawyer'],
  methods: {
    getSubspecialities(speciality) {
      return _.filter(this.lawyer.subspecialities, s => {
        return s.speciality_id === speciality.id;
      });
    }
  }
}
</script>

<style scoped>

</style>