import jobs from "../../api/jobs";

let _ = require('lodash');
import { useToast } from 'vue-toastification';
import providers from "../../api/providers";
import router from "../../router";

const toast = useToast();

export const state = {
    currentStage: 0,
    isLoading: false,
    context: null,
    serverErrors: [],

    profession: null,

    speciality: null,
    subspecialities: [],

    isLocationImportant: null,

    budgetMin: null,
    budgetMax: null,

    longitude: null,
    latitude: null,
    radius: null,

    premiumProvidersOnly: false,

    jobTitle: '',
    jobDescription: '',

    shouldInviteClient: true,
    clientName: '',
    clientEmail: '',

    isSearching: false,
    // resultsPerPage: 10,
    resultsPerPage: 100,
    lawyerResults: [],
    lawyersSelected: [],

    lawyerSuggestions: [],

    isSaving: false
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true
    },

    STOP_LOADING(state) {
        state.isLoading = false
    },

    SET_CONTEXT(state, context) {
        state.context = context;
    },

    SET_ERRORS(state, errors) {
        state.serverErrors = errors
    },

    SET_PROFESSION(state, profession) {
        state.profession = profession;
    },

    SET_SPECIALITY(state, speciality) {
        state.speciality = speciality
    },

    SET_WIZARD_STAGE(state, stage) {
        state.currentStage = stage;
    },

    INCREMENT_WIZARD_STAGE(state) {
        state.currentStage += 1
    },

    DECREMENT_WIZARD_STAGE(state) {
        state.currentStage -= 1
    },

    SET_SUBSPECIALITIES(state, subspecialities) {
        state.subspecialities = subspecialities;
    },

    TOGGLE_SUBSPECIALITY(state, subspeciality) {
        state.subspecialities = _.xorBy(state.subspecialities, [subspeciality], 'id');
    },

    SET_IS_LOCATION_IMPORTANT(state, isLocationImportant) {
        state.isLocationImportant = isLocationImportant;
    },

    SET_LONGITUDE(state, longitude) {
        state.longitude = longitude;
    },

    SET_LATITUDE(state, latitude) {
        state.latitude = latitude;
    },

    SET_RADIUS(state, radius) {
        state.radius = radius;
    },

    SET_PREMIUM_PROVIDERS_ONLY(state, premiumProvidersOnly) {
        state.premiumProvidersOnly = premiumProvidersOnly;
    },

    SET_BUDGET_MIN(state, budgetMin) {
        state.budgetMin = budgetMin;
    },

    SET_BUDGET_MAX(state, budgetMax) {
        state.budgetMax = budgetMax;
    },

    SET_JOB_TITLE(state, jobTitle) {
        state.jobTitle = jobTitle;
    },

    SET_JOB_DESCRIPTION(state, jobDescription) {
        state.jobDescription = jobDescription;
    },

    SET_CLIENT_NAME(state, clientName) {
        state.clientName = clientName;
    },

    SET_CLIENT_EMAIL(state, clientEmail) {
        state.clientEmail = clientEmail;
    },

    SET_LAWYER_RESULTS(state, results) {
        state.lawyerResults = results;
    },

    APPEND_LAWYER_RESULTS(state, results) {
        state.lawyerResults.data = _.uniqBy(_.flattenDeep([state.lawyerResults.data, results.data]), 'id');
    },

    SET_LAWYER_SUGGESTIONS(state, suggestions) {
        state.lawyerSuggestions = suggestions;
    },

    START_SEARCHING(state) {
        state.isSearching = true;
    },

    STOP_SEARCHING(state) {
        state.isSearching = false;
    },

    SET_LAWYERS_SELECTED(state, lawyers) {
        state.lawyersSelected = lawyers;
    },

    TOGGLE_LAWYER_SELECTED(state, lawyer) {
        state.lawyersSelected = _.xorBy(state.lawyersSelected, [lawyer], 'id');
    },

    SET_SHOULD_INVITE_CLIENT(state, shouldInviteClient) {
        state.shouldInviteClient = shouldInviteClient;
    },

    START_SAVING(state) {
        state.isSaving = true;
    },

    STOP_SAVING(state) {
        state.isSaving = false;
    }
};

export const getters = {
    isLoading: (state) => (state.isLoading),
    context: (state) => (state.context),
    contextMinHourlyRateInPounds: (state) => (
        state.context['min_hourly_rate'] ? state.context['min_hourly_rate'] / 100 : 0
    ),
    contextMaxHourlyRateInPounds: (state) => (
        state.context['max_hourly_rate'] ? state.context['max_hourly_rate'] / 100 : 0
    ),
    currentStage: (state) => (state.currentStage),
    serverErrors: (state) => (state.serverErrors),
    profession: (state) => (state.profession),
    speciality: (state) => (state.speciality),
    subspecialities: (state) => (state.subspecialities),
    isLocationImportant: (state) => (state.isLocationImportant),
    longitude: (state) => (state.longitude),
    latitude: (state) => (state.latitude),
    radius: (state) => (state.radius),
    premiumProvidersOnly: (state) => (state.premiumProvidersOnly),
    budgetMin: (state) => (state.budgetMin),
    budgetMax: (state) => (state.budgetMax),
    shouldInviteClient: (state) => (state.shouldInviteClient),
    resultsPerPage: (state) => (state.resultsPerPage),
    lawyerResults: (state) => (state.lawyerResults),
    lawyerSuggestions: (state) => (state.lawyerSuggestions),
    isSearching: (state) => (state.isSearching),
    lawyersSelected: (state) => (state.lawyersSelected),
    jobTitle: (state) => (state.jobTitle),
    jobDescription: (state) => (state.jobDescription),
    clientName: (state) => (state.clientName),
    clientEmail: (state) => (state.clientEmail),
    isSaving: (state) => (state.isSaving)
};

export const actions = {
    setContext({ commit }, context) {
        commit('SET_CONTEXT', context);
    },

    setProfession({ commit }, profession) {
        commit('SET_PROFESSION', profession);
    },

    setSpeciality({ commit }, speciality) {
        commit('SET_SPECIALITY', speciality)
    },

    setWizardStage({ commit }, stage) {
        commit('SET_WIZARD_STAGE', stage);
    },

    incrementWizardStage({ commit }) {
        commit('INCREMENT_WIZARD_STAGE')
    },

    decrementWizardStage({ commit, getters }) {
        commit('DECREMENT_WIZARD_STAGE')
        if (getters.currentStage == 1) {
            commit('SET_SPECIALITY', null);
            commit('SET_SUBSPECIALITIES', []);
        }
    },

    setSubspecialities({ commit }, subspecialities) {
        commit('SET_SUBSPECIALITIES', subspecialities);
    },

    toggleSubspeciality({ commit }, subspeciality) {
        commit('TOGGLE_SUBSPECIALITY', subspeciality);
    },

    setIsLocationImportant({ commit }, isLocationImportant) {
        commit('SET_IS_LOCATION_IMPORTANT', isLocationImportant);
    },

    setLatitude({ commit }, lat) {
        commit('SET_LATITUDE', lat);
    },

    setLongitude({ commit }, lng) {
        commit('SET_LONGITUDE', lng);
    },

    setRadius({ commit }, radius) {
        commit('SET_RADIUS', radius);
    },

    setPremiumProvidersOnly({ commit }, premiumProvidersOnly) {
        commit('SET_PREMIUM_PROVIDERS_ONLY', premiumProvidersOnly);
    },

    initialiseBudgetFilter({ commit, getters }) {
        if (!getters.budgetMin) {
            commit('SET_BUDGET_MIN', getters.contextMinHourlyRateInPounds);
        }

        if (!getters.budgetMax) {
            commit('SET_BUDGET_MAX', getters.contextMaxHourlyRateInPounds);
        }
    },

    setBudgetMin({ commit }, min) {
        commit('SET_BUDGET_MIN', min);
    },

    setBudgetMax({ commit }, max) {
        commit('SET_BUDGET_MAX', max);
    },

    setLawyersSelected({ commit }, lawyers) {
        commit('SET_LAWYERS_SELECTED', lawyers);
    },

    toggleLawyerSelected({ commit }, lawyer) {
        commit('TOGGLE_LAWYER_SELECTED', lawyer);
    },

    searchLawyers({ commit, getters, dispatch }, params = {}) {
        return new Promise((resolve, reject) => {
            commit('START_SEARCHING', true);
            commit('SET_ERRORS', []);

            // Determine search params
            let searchParams = {};

            if (getters.resultsPerPage) {
                searchParams['results_per_page'] = getters.resultsPerPage;
            }
            if (getters.speciality) {
                searchParams['speciality_id'] = getters.speciality.id;
            }
            if (getters.subspecialities) {
                searchParams['subspeciality_ids'] = _.map(
                    getters.subspecialities, 'id'
                );
                // searchParams['subspeciality_ids_search_type'] = 'any';
                searchParams['subspeciality_ids_search_type'] = 'rank';
            }
            if (getters.budgetMin) {
                searchParams['hourly_rate_min'] = getters.budgetMin * 100;
            }
            if (getters.budgetMax) {
                searchParams['hourly_rate_max'] = getters.budgetMax * 100;
            }
            if (getters.isLocationImportant && getters.latitude && getters.longitude) {
                searchParams['location'] = {
                    'latitude': getters.latitude,
                    'longitude': getters.longitude,
                    'radius': getters.radius,
                    'radius_unit': 'mi'
                };
            }
            if (getters.premiumProvidersOnly) {
                searchParams['premium_only'] = true;
            }

            // Perform search
            providers.search({
                ...searchParams,
                page: 1,
                ...params
            }).then(r => {
                if (params['page'] && params['page'] > 1) {
                    commit('APPEND_LAWYER_RESULTS', r.data);
                } else {
                    commit('SET_LAWYER_RESULTS', r.data);
                }
                commit('STOP_SEARCHING');
                if (getters.lawyerResults.data.length == 0) {
                    dispatch('suggestLawyers');
                }
                resolve(r.data);
            }).catch(e => {
                console.log(e);
                commit('SET_LAWYER_RESULTS', []);
                commit('STOP_SEARCHING');
                toast.error('Error searching lawyers');
                let errors;
                if (typeof e.response.data === 'object') {
                    errors = _.flatten(_.toArray(e.response.data.errors));
                } else {
                    errors = ['Something went wrong. Please try again.'];
                }
                commit('SET_ERRORS', errors);
                reject(e);
            });
        });
    },

    suggestLawyers({ commit, getters, dispatch }, params = {}) {
        commit('START_SEARCHING', true);

        // Determine search params
        let searchParams = {};

        if (getters.resultsPerPage) {
            searchParams['results_per_page'] = getters.resultsPerPage;
        }
        if (getters.speciality) {
            searchParams['speciality_id'] = getters.speciality.id;
        }
        if (getters.subspecialities) {
            searchParams['subspeciality_ids'] = _.map(
                getters.subspecialities, 'id'
            );
            searchParams['subspeciality_ids_search_type'] = 'any';
        }
        if (getters.budgetMin) {
            searchParams['hourly_rate_min'] = getters.budgetMin * 100;
        }
        if (getters.budgetMax) {
            searchParams['hourly_rate_max'] = getters.budgetMax * 100;
        }
        if (getters.isLocationImportant && getters.latitude && getters.longitude) {
            searchParams['location'] = {
                'latitude': getters.latitude,
                'longitude': getters.longitude,
                'radius': getters.radius,
                'radius_unit': 'mi'
            };
        }
        // Perform search
        providers.suggest({
            ...searchParams,
            ...params
        }).then(r => {
            commit('SET_LAWYER_SUGGESTIONS', r.data);
            commit('STOP_SEARCHING');
        }).catch(e => {
            console.log(e);
            commit('SET_LAWYER_SUGGESTIONS', []);
            commit('STOP_SEARCHING');
            toast.error('Error suggesting lawyers');
            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        });
    },

    setJobTitle({ commit }, title) {
        commit('SET_JOB_TITLE', title);
    },

    setJobDescription({ commit }, description) {
        commit('SET_JOB_DESCRIPTION', description);
    },

    setShouldInviteClient({ commit }, shouldInviteClient) {
        commit('SET_SHOULD_INVITE_CLIENT', shouldInviteClient);
    },

    setClientName({ commit }, clientName) {
        commit('SET_CLIENT_NAME', clientName);
    },

    setClientEmail({ commit }, clientEmail) {
        commit('SET_CLIENT_EMAIL', clientEmail);
    },

    reset({ commit }) {
        commit('SET_ERRORS', []);
        commit('SET_SPECIALITY', null);
        commit('SET_SUBSPECIALITIES', []);
        commit('SET_IS_LOCATION_IMPORTANT', null);
        commit('SET_LATITUDE', null);
        commit('SET_LONGITUDE', null);
        commit('SET_RADIUS', null);
        commit('SET_BUDGET_MIN', null);
        commit('SET_BUDGET_MAX', null);
        commit('SET_JOB_TITLE', null);
        commit('SET_JOB_DESCRIPTION', null);
        commit('SET_SHOULD_INVITE_CLIENT', true);
        commit('SET_CLIENT_NAME', null);
        commit('SET_CLIENT_EMAIL', null);
        commit('SET_LAWYER_RESULTS', []);
        commit('SET_LAWYERS_SELECTED', []);
        commit('SET_WIZARD_STAGE', 0);
    },

    saveJob({ commit, getters, dispatch, rootGetters }) {
        commit('START_SAVING');
        commit('SET_ERRORS', []);

        let params = {
            speciality_id: getters.speciality ? getters.speciality.id : null,
            subspeciality_ids: getters.subspecialities ? _.map(getters.subspecialities, 'id') : [],
            title: getters.jobTitle,
            description: getters.jobDescription,
            // client_name: getters.clientName,
            // client_email: getters.clientEmail,
            hourly_rate_min: getters.budgetMin ? getters.budgetMin * 100 : null,
            hourly_rate_max: getters.budgetMax ? getters.budgetMax * 100 : null,
            provider_user_role_ids: _.map(getters.lawyersSelected, 'id')
        };

        let isClient = rootGetters['auth/isClient'];
        if (!isClient && getters.shouldInviteClient) {
            params['should_invite_client'] = true;
            params['client_name'] = getters.clientName;
            params['client_email'] = getters.clientEmail;
        } else {
            params['should_invite_client'] = false;
        }

        if (getters.isLocationImportant) {
            params['location_is_important'] = true;
            params['latitude'] = getters.latitude;
            params['longitude'] = getters.longitude;
            params['radius'] = getters.radius;
            params['radius_unit'] = 'km';
        }

        jobs.save(params).then(r => {
            commit('STOP_SAVING');
            dispatch('reset');
            toast.success('Job created successfully');
            router.push('/jobs/' + r.data.id);
        }).catch(e => {
            console.log(e);
            commit('STOP_SAVING');
            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            toast.error(errors[0]);
            commit('SET_ERRORS', errors);
        });
    }
};
