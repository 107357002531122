<template>
   <footer class="footer bg-dark pt-4">
      <div class="container py-2">
        <!-- Copyright-->
        <p class="fs-sm text-center text-sm-start mb-4"><span class="text-light opacity-50">&copy; Syndesi One Limited. All rights reserved.</span></p>
      </div>
    </footer>
</template>

<script>
export default {
    setup() {
        
    },
}
</script>


<style>

</style>
